import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import NotFound from "../views/NotFound.vue";
import { pathList } from "@/utils/config.js";
import { Message } from 'element-ui';
Vue.use(VueRouter);

const routes = [
  {
    path:'*',
    name:'NotFound',
    component:NotFound,
  },
  {
    path: "/",
    name: "Home",
    component:()=>import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
  },
  {
    path: "/camp",
    name: "Camp",
    component: () =>
    import(/* webpackChunkName: "Camp" */ "../views/camp/index.vue"),
    props: true,
  },
  {
    path: "/camp/detail/:id",
    name: "CampDetail",
    component: () =>
    import(/* webpackChunkName: "Camp" */ "../views/camp/detail.vue"),
    props: true,
  },
  {
    path: "/course",
    name: "Course",
    component: () =>
    import(/* webpackChunkName: "Course" */ "../views/course/index.vue"),
    props: true,
  },
  {
    path: "/course/detail/:id",
    name: "CourseDetail",
    component: () =>
    import(/* webpackChunkName: "Course" */ "../views/course/detail.vue"),
    props: true,
  },
  {
    path: "/school",
    name: "School",
    component: () =>
    import(/* webpackChunkName: "School" */ "../views/school/index.vue"),
    props: true,
  },
  {
    path: "/school/detail/:id",
    name: "SchoolDetail",
    component: () =>
    import(/* webpackChunkName: "School" */ "../views/school/detail.vue"),
    props: true,
  },
  {
    path: "/itinerary",
    name: "Itinerary",
    meta : {
      // normal : true
    },
    component: () =>
      import(/* webpackChunkName: "Itinerary" */ "../views/itinerary/index.vue"),
  },
  {
    path: "/itinerary/detail/:id",
    name: "ItineraryDetail",
    meta : {
      // normal : true
    },
    component: () =>
      import(/* webpackChunkName: "Itinerary" */ "../views/itinerary/detail.vue"),
  },
  {
    path: "/article",
    name: "Article",
    meta : {
      // normal : true
    },
    component: () =>
      import(/* webpackChunkName: "Article" */ "../views/article/index.vue"),
  },
  {
    path: "/article/detail/:id",
    name: "ArticleDetail",
    meta : {
      // normal : true
    },
    component: () =>
      import(/* webpackChunkName: "Article" */ "../views/article/detail.vue"),
  },
  {
    path: "/teacher/:id",
    name: "TeacherDetail",
    meta : {
      // normal : true
    },
    component: () =>
      import(/* webpackChunkName: "Article" */ "../views/teacher/index.vue"),
  },
  {
    path: "/pay",
    name: "Pay",
    meta : {
      normal : true
    },
    component: () =>
      import(/* webpackChunkName: "Pay" */ "../views/pay/index.vue"),
  },
  {
    path: "/red/:type",
    name: "Red",
    component: () =>
      import(/* webpackChunkName: "Red" */ "../views/red/Index.vue"),
    props: true,
  },
  {
    path: "/red/detail/:id",
    name: "Detail",
    component: () =>
      import(/* webpackChunkName: "Detail" */ "../views/red/Detail.vue"),
    props: true,
  },
  {
    // path: "/classroom/:type",
    path: "/classroom",
    name: "Classroom",
    component: () =>
      import(/* webpackChunkName: "Classroom" */ "../views/classroom/Index.vue"),
    props: true,
  },
  {
    path: "/classroom/detail/:id",
    name: "classroomDetail",
    component: () =>
      import(/* webpackChunkName: "Classroom" */ "../views/classroom/Detail.vue"),
    props: true,
  },
  {
    path: "/news/:type",
    name: "News",
    component: () =>
      import(/* webpackChunkName: "News" */ "../views/news/Index.vue"),
    props: true,
  },
  {
    path: "/news/detail/:id",
    name: "newsDetail",
    component: () =>
      import(/* webpackChunkName: "News" */ "../views/news/Detail.vue"),
    props: true,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "About" */ "../views/About.vue"),
  },
  {
    path: "/research",
    name: "Research",
    component: () =>
      import(/* webpackChunkName: "Research" */ "../views/kanban/research.vue"),
  },
  {
    path: "/line/:type",
    name: "Line",
    component: () =>
      import(/* webpackChunkName: "Line" */ "../views/line/index.vue"),
  },
  {
    path: "/line/detail/:id",
    name: "LineDetail",
    component: () =>
      import(/* webpackChunkName: "Line" */ "../views/line/detail.vue"),
  },
  {
    path: "/bigData",
    name: "bigData",
    component: () =>
      import(/* webpackChunkName: "bigData" */ "../views/big/index.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/login/index.vue"),
  },
  {
    path: "/bind",
    name: "Bind",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/login/bind.vue"),
  },
  {
    path: "/creation",
    name: "Creation",
    meta : {
      normal : true
    },
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/creation/index.vue"),
  },
  {
    path: "/creation/question",
    name: "CreationQuestion",
    meta : {
      normal : true
    },
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/creation/question/index.vue"),
  },

  // {
  //   path: "/itinerary/flow",
  //   name: "Flow",
  //   meta : {
  //     normal : true
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "Login" */ "../views/itinerary/flow.vue"),
  // },
  {
    path: "/itinerary_new/flowNew",
    name: "FlowNew",
    meta : {
      normal : true
    },
    component: () =>
      import(/* webpackChunkName: "FlowNew" */ "../views/itinerary_new/flowNew.vue"),
  },
  {
    path: "/resident",
    name: "Resident",
    meta : {
      normal : false
    },
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/resident/index.vue"),
  },
  {
    path: "/user",
    name: "User",
    meta : {
      // normal : true
    },
    component: () =>
      import(/* webpackChunkName: "User" */ "../views/user/index.vue"),
  },
  {
    path: "/activity",
    name: "Activity",
    meta : {
      normal : true
    },
    component: () =>
      import(/* webpackChunkName: "Activity" */ "../views/activity/index.vue"),
  },
  {
    path: "/activity/detail",
    name: "ActivityDetail",
    meta : {
      normal : true
    },
    component: () =>
      import(/* webpackChunkName: "Activity" */ "../views/activity/detail.vue"),
  },
  {
    path: "/search",
    name: "Search",
    meta : {
      normal : true
    },
    component: () =>
      import(/* webpackChunkName: "Search" */ "../views/search/index.vue"),
  },
  // ,
  // {
  //   path: '/student',
  //   name: 'Student',
  //   component: () => import(/!* webpackChunkName: "Student" *!/ '../views/kanban/student.vue')
  // },
];

const router = new VueRouter({
  mode:'history',
  routes,
});
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {

  // 课程详情页 面包屑 地址  开始
  // console.log(to);
  if(to.meta.normal){
    var token = localStorage.getItem("token");
    if(!token){
        Message("您还没登录,请先登录");
        return;
    }
    // var userInfo = JSON.parse(localStorage.getItem('userInfo'));
    // if(userInfo.type == 2) {
    //   Message("对不起，您没权限");
    //   if(to.path !='/'){
    //     next('/')
    //   }
    //   return false;
    // }
  }
  // let path = pathList(to.path);
  // console.log(to);
  // if (path) {
  //   localStorage.setItem("pathList", JSON.stringify(path));
  // } 
  //设置分辨率
      let docEle = document.documentElement,
        resizeEvt =
            'orientationchange' in window ? 'orientationchange' : 'resize',
        resizeFun = () => {
            let clientWidth = docEle.clientWidth;
            // rem = width /10
            // if(clientWidth < 1000) {
            //   // docEle.style.overflowX = 'auto'
            //   return; 
            // }
            if (!clientWidth) return;
            let rem = ''
            let dpr =1
            let width = clientWidth
              rem = width /10

            // if(to.path == '/itinerary/flow' || to.path == '/itinerary/flowNew') {
            //   rem = width /10
            // }else{
            //   if (width / dpr > 1200 && width / dpr <= 1920) {
            //       width = 1920 * dpr;
            //       rem = width / 10

            //   }else if (width / dpr >= 1000 && width / dpr <= 1200) {
            //       width = width * dpr;
            //       rem = width / 8.8;
            //   }else{
            //       // console.log('小于1000');
            //       width = 1000 * dpr;
            //       rem = width / 6.4;
            //   }
            // }
            docEle.style.fontSize =rem + 'px';
            window.document.body.style.fontSize = 14+'px';
        };
    if (!document.addEventListener) return;
    window.addEventListener(resizeEvt, resizeFun, false);
    window.addEventListener('DOMContentLoaded', resizeFun, false);
    resizeFun();



  // 课程详情页 面包屑 地址  结束
  next();
});
export default router;
