<template>
  <footer>
    <div class="page_footer" ref="footer">
      <div class="page_footer_content">
        <!-- <img src="@/assets/images/logo.png" /> -->
        <div style="font-size:.0625rem;">
          <a
            class="gov"
            href="https://beian.miit.gov.cn/"
            target="_blank"
            >
            鲁ICP备2023015371号</a>
            <a
            class="gov"
            href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37011202001955"
            target="_blank"
            >
            <img style="width:.0729rem;height:.0729rem;" src="../assets/images/gongan.png" alt="">
            鲁公网安备 37011202001955号</a>
            <a
            class="gov"
            href="https://beian.miit.gov.cn/"
            target="_blank"
            >
              广播电视节目制作经营许可证：(鲁)字第02478号
            </a>
            <a class="gov" href="javascript:void(0);"> Copyright © 2020-2023 黄河流域研学教育科技（山东）有限公司 版权所有</a>
        </div>
      </div>

    </div>
    <!-- <div class="bk_footer"></div> -->
  </footer>
</template>

<script>
export default {
  name: "PageFooter",
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/const.scss";
footer {
  .page_footer {
    width: 100%;
    height:300px;
    background: $default_color;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &_content {
      width:$content-width;
      height:100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: .4896rem;
      img {
        height: .2604rem;
      }
      div {
        flex:1;
        text-align: center;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .info{
        }
        .gov{
          min-width:100px;
          margin-top: .1042rem;
          color:#fff;
        }
        a{
          font-size: .0833rem;
        }
      }
    }
  }
  .bk_footer {
    // margin-top: .4688rem;
    height: .5208rem;
    background: url("../static/bkground/bkground.png") no-repeat;
  }
}
</style>
