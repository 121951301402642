<template>
  <div id="app" v-cloak>
    <component :is="curHeader" />
    <div
      class="wrapper"
      :style="{ backgroundImage: isbig == '' ? 'url('+bgImage+')' : ''}"

    >
      <router-view />
    </div>
    <page-footer v-if="isFooter" />
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader";
import IndexHeader from "@/components/IndexHeader";
import PageFooter from "@/components/PageFooter";

export default {
  components: {
    IndexHeader,
    PageHeader,
    PageFooter,
  },
  data() {
    return {
      bgImage:require('./assets/pageBg1.png'),
      bgStyle:{
        backgroundImage:'url('+require('./assets/pageBg1.png')+')'
      },
      style: {
        backgroundColor: "rgba(0, 0, 59, 1)",
      },
    };
  },
  mounted(){
  },
  methods:{
  },
  computed: {
    isbig() {
      const { path } = this.$route;
      if (path == "/bigData") {
        return "";
      }
      return true
    },
    curHeader() {
      const { path } = this.$route;
      if (
        path == "/bigData" ||
        path == "/login" ||
        path == "/bind" ||
        path == "/resident"
        || path == '/itinerary/flow'
        || path == '/itinerary/flowNew'
        || path == '/search'
        || path == '/camp'
        || path == '/itinerary'
        || path == '/course'
        || path == '/school'
      ) {
        return "";
      }
      if (path === "/") return "";
      // if (path === "/") return "";
      return "IndexHeader";
    },
    isFooter() {
      var arr = ["/bigData", "/login", "/bind",'/resident','/itinerary/flow','/itinerary/flowNew'];
      var flag = arr.findIndex((item) => item === this.$route.path);
      if (flag >= 0) {
        return false;
      } else {
        return true;
      }
      // this.$route.path != '/bigData' || this.$route.path !='/login'
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/styles/const.scss";
@import "~@/assets/scss/variables.scss";
html,
body {
  // overflow-x: hidden;
  height: 100%;
  min-width: 1200px;
  //   filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);//做灰处理
  // -webkit-filter: grayscale(100%);//做灰处理
}
#app {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  font-family: "PingFang SC", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666;
  font-size: 14px;
  overflow-x:'auto';
  // line-height: 2;
  
}
.content {
  @include center-content;
  box-sizing: border-box;
}
.wrapper {
  flex: 1;
  background-size: 100% 100%;
  background-repeat:no-repeat;
}

.section_title {
  position: relative;
  padding-bottom: 26px;
  color: #18334d;
  font-size: 32px;
  line-height: 1;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 32px;
    height: 3px;
    background: #7da5cb;
  }
}
.bread_crumbs {
  display: flex;
  align-items: center;
  height: 106px;
}
.bg-color-black {
  // background-color: rgba(19, 25, 47, 0.6);
  background-color: rgba(65, 121, 159,.35);

}

</style>
