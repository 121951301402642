import {httpInstance} from './service'

export const areaAll = type => {
  return httpInstance.get('/api/wxapp/home_page/areaAll', {
    params: {
      type
    }
  })
}
export const getSelectType = (data) => {

  var token = localStorage.getItem('token') ?? '';
  return httpInstance.get('/api/wxapp/home_page/getSelectType',{
    params:{...data}
  })
}

// -----------------------------------------------------------------------------------------------------
export const getHot = (data) => {
  return httpInstance.get('/api/wxapp/home_page/getHot', {
    params: {
      ...data
    }
  })
}
export const getInit2 = (data) => {
  return httpInstance.get('/api/wxapp/home_page/getInit2', {
    params: {
      ...data
    }
  })
}


export const getBanner = (data) => {
  return httpInstance.get('/api/wxapp/home_page/banner', {
    params: {
      ...data
    }
  })
}

export const getRedCanal = (type, page) => {
  return httpInstance.get('/api/wxapp/red_canal/index', {
    params: {
      type,
      page,
      // limit: 10 修改总条数以前是10现在改成9个
      limit: 9
    }
  })
}

export const getRedDetail = (type, id) => {
  console.log(type, id)
  return httpInstance.get('/api/wxapp/red_canal/info', {
    params: {
      type,
      id
    }
  })
}
export const getCourseInit = (type_one, page=1,limit=9,classname='') => {
  return httpInstance.get('/api/wxapp/course/init', {
    params: {
      type_one,
      page,
      limit:limit,
      classname
    }
  })
}
// eslint-disable-next-line camelcase
export const getCourse = (type_one, page=1,limit=9,classname='') => {
  return httpInstance.get('/api/wxapp/course/index', {
    params: {
      type_one,
      page,
      limit:limit,
      classname
    }
  })
}

export const getCourseDetail = (type, id) => {
  let userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : '';
  let token = userInfo.token;
  let is_school = userInfo.is_school
  return httpInstance.post('/api/wxapp/course/info', {
      type,
      id,
      token,
      is_school
  })
}

// eslint-disable-next-line camelcase
export const getNews = (type_id, page,limit='8') => {
  return httpInstance.get('/api/wxapp/home_page/article', {
    params: {
      type_id,
      page,
      limit: limit
    }
  })
}

export const getNewsDetail = (type, id) => {
  return httpInstance.get('/api/wxapp/home_page/articleInfo', {
    params: {
      type,
      id
    }
  })
}

export const getItinerary3 = (data) => {

  var token = localStorage.getItem('token') ?? '';
  return httpInstance.post('/api/wxapp/canal_research/itinerary3',{...data})
}
export const getItinerary = (type=1,page=1,limit=8,types=1) => {

  var token = localStorage.getItem('token') ?? '';
  return httpInstance.get('/api/wxapp/canal_research/itinerary',{
    params:{
      type,
      limit,
      page,
      types,
      token
    }
  })
}
//记录保存
export const saveHist = (params) => {
  return httpInstance.post('/api/wxapp/home_page/saveHist',{
    ...params
  })
}

export const getItineraryInfo = (params) => {
  return httpInstance.get('/api/wxapp/canal_research/itineraryInfoNew',{
    params
  })
}
export const getCode = (params) => {
  return httpInstance.get('/api/wxapp/send_sms/main',{
    params
  })
}

export const getLoginQr = (params) => {
  return httpInstance.post('/api/wxapp/user/getLoginQr',{
    params
  })
}


export const submit = (params) => {
  return httpInstance.post('/api/wxapp/user/login',{
    params
  })
}
// export const submit2 = (params) => {
//   return httpInstance.post('/api/wxapp/user/schoolLogin',params)
// }
export const getUserInfo = (params) => {
  return httpInstance.post('/api/wxapp/user/getUserInfo2',{
    params
  })
}
export const bindPhone = (params) => {
  return httpInstance.post('/api/wxapp/user/bindPhone',{
    params
  })
}
//获取所有城市
export const getAllRegion = () => {
  return httpInstance.get('/api/wxapp/region/all')
}
//获取所有标签
export const getAllLabel = () => {
  return httpInstance.get('api/wxapp/label/index')
}
// 
//获取所有标签
export const ResearchPointList = (params={}) => {
  // console.log(params)
  var token = localStorage.getItem('token') ?? '';
  // var token = '';
  params.token = token
  return httpInstance.post('api/wxapp/canal_research/ResearchPointList',params)
}
//获取所有标签
export const getFile = (params={}) => {
  var token = localStorage.getItem('token') ?? '';
  // var token = '';
  params.token = token
  return httpInstance.post('api/wxapp/principal_message/index',params)
}
// api/wxapp/upload/uploadPicture
//上传图片
export const uploadPicture = (params={}) => {
  var token = localStorage.getItem('token') ?? '';
  // var token = '';
  // params.token = token
  // console.log(params);
  return httpInstance.post('api/wxapp/upload/uploadPicture',params)
}
//研学点相关课程
export const getItineraryCourse = (params={}) => {
  var token = localStorage.getItem('token') ?? '';
  params.token = token
  return httpInstance.post('api/wxapp/canal_research/itineraryCourse',params)
}
//研学点添加
export const addItineraryNew = (params={}) => {
  var token = localStorage.getItem('token') ?? '';
  params.token = token
  return httpInstance.post('api/wxapp/canal_research/addItineraryNew',params)
}

export const  wonderful =  (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  // return httpInstance.post('api/wxapp/wonderful/growthReport',params);
  return httpInstance.post('api/wxapp/wonderful/index',params);
}
//物料准备 提交
export const materialSubmit = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  // return httpInstance.post('api/wxapp/wonderful/growthReport',params);
  return httpInstance.post('api/wxapp/wonderful/materialSubmit',params);
}
export const  growthReport =  (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  // return httpInstance.post('api/wxapp/wonderful/growthReport',params);
  return httpInstance.post('api/wxapp/wonderful/detailInfo',params);
}
//旅途状态 提交
export const tripSubmit = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  // return httpInstance.post('api/wxapp/wonderful/growthReport',params);
  return httpInstance.post('api/wxapp/wonderful/tripSubmit',params);
}
//研学点 提交
export const punchSubmit = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  // return httpInstance.post('api/wxapp/wonderful/growthReport',params);
  return httpInstance.post('api/wxapp/wonderful/punchSubmit',params);
}
// handSubmit
//动手实践提交
export const handSubmit = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  // return httpInstance.post('api/wxapp/wonderful/growthReport',params);
  return httpInstance.post('api/wxapp/wonderful/handSubmit',params);
}
//视频上传
export const uploadVideo = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  // return httpInstance.post('api/wxapp/wonderful/growthReport',params);
  return httpInstance.post('api/wxapp/upload/uploadVideo',params);
}
//团队合作保存
export const teamSubmit = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  // return httpInstance.post('api/wxapp/wonderful/growthReport',params);
  return httpInstance.post('api/wxapp/wonderful/teamSubmit',params);
}

//研学点主题
export const getLabel = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  // return httpInstance.post('api/wxapp/wonderful/growthReport',params);
  return httpInstance.post('api/wxapp/question/label',params);
}
//研学点价值体认
export const getRecog = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  // return httpInstance.post('api/wxapp/wonderful/growthReport',params);
  return httpInstance.post('api/wxapp/question/getRecog',params);
}

//研学点课程+测试题
export const getQuestion = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  // return httpInstance.post('api/wxapp/wonderful/growthReport',params);
  return httpInstance.post('api/wxapp/question/index',params);
}
// getCourseLabel
//研学点课程+测试题
export const getCourseLabel = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  // return httpInstance.post('api/wxapp/wonderful/growthReport',params);
  return httpInstance.post('api/wxapp/question/getCourseLabel',params);
}
// courseAdd
//研学点课程+测试题
export const courseAdd = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  // return httpInstance.post('api/wxapp/wonderful/growthReport',params);
  return httpInstance.post('api/wxapp/question/courseAdd',params);
}
// pointCourse
// courseAdd
//研学点下属课程
export const pointCourse = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  // return httpInstance.post('api/wxapp/wonderful/growthReport',params);
  return httpInstance.post('api/wxapp/question/pointCourse',params);
}
//自定义试题 添加
export const questAdd = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  // return httpInstance.post('api/wxapp/wonderful/growthReport',params);
  return httpInstance.post('api/wxapp/question/add',params);
}

//线路流程状态
export const itineraryStatus = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  return httpInstance.post('api/wxapp/wonderful/status',params);
}


//自我评估
export const previewSubmit = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  return httpInstance.post('api/wxapp/wonderful/previewSubmit',params);
}

//探索求知
export const getPointCourseDetail = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  return httpInstance.post('api/wxapp/question/getPointCourseDetail',params);
}

//探索求知 做一做 提交
export const submitPointQuestion = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  return httpInstance.post('api/wxapp/wonderful/submitPointQuestion',params);
}

//线下 价值体认 提交
export const submitPointQuestion2 = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  return httpInstance.post('api/wxapp/wonderful/submitPointQuestion2',params);
}

//线下 过程方法与对应事项
export const getbooks = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  return httpInstance.post('api/wxapp/wonderful/getbooks',params);
}
// 线下 过程方法与对应事项  移除
export const removeBooks = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  return httpInstance.post('api/wxapp/wonderful/removeBooks',params);
}
//探索求知 测一测 获取试题
export const getQuestions = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  return httpInstance.post('api/wxapp/wonderful/getQuestions',params);
}

//探索求知 测一测 获取试题
export const selfSubmit = (params={})=>{
  var token = localStorage.getItem('token') ?? '';
  params.token = token;
  return httpInstance.post('api/wxapp/wonderful/selfSubmit',params);
}

export const rubbishSubmit = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/wonderful/rubbishSubmit',params);
}
export const residentSubmit = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/user/residentSubmit',params);
}

export const getPointCenter = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/canal_research/getPointCenter',params);
}
/**
 * 获取系统提供的教学线路
 * @param {*} params 
 * @returns 
 */
export const getCourseIniterary = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/canal_research/getCourseIniterary',params);
}

/**
 * 保存老师选择的线路
 * @param {*} params 
 * @returns 
 */
 export const saveCourseIniterary = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/canal_research/saveCourseIniterary',params);
}
/**
 * 获取老师选择的线路
 * @param {*} params 
 * @returns 
 */
 export const getTeacherCourse = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/canal_research/getTeacherCourse',params);
}
/**
 * 保存路线开始时间
 * @param {*} params 
 * @returns 
 */
 export const saveCourseStartTime = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/canal_research/saveCourseStartTime',params);
}

/**
 * 保存老师自定义课程内容
 * @param {*} params 
 * @returns 
 */
 export const saveCourseContent = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/canal_research/saveCourseContent',params);
}

/**
 * 删除路线
 * @param {*} params 
 * @returns 
 */
 export const delTeacherCourse = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.delete('api/wxapp/canal_research/delTeacherCourse',{params});
}

export const getUserItinerary = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/canal_research/getUserItinerary',params);
}
export const delUserItinerary = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.delete('api/wxapp/canal_research/delUserItinerary',{params});
}

export const getRecordingDetail = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.get('api/wxapp/user_center/itineraryInfo',{params});
}
//修改路线
export const itineraryRevise = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/user_center/itineraryRevise',params);
}


export const getArticleListApi = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/article/index',params);
}
export const saveArticleApi = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/article/save',params);
}

export const delArticleApi = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.delete('api/wxapp/article/delete',{params});
}


export const getItineraryApi = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/itinerary/index',params);
}
export const saveItineraryApi = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/itinerary/save',params);
}


export const getWindListApi = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/article/windList',params);
}
export const saveWindListApi = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/article/saveWind',params);
}
// export const delArticleApi = (params = {})=>{
//   var token = localStorage.getItem('token')??'';
//   params.token = token;
//   return httpInstance.delete('api/wxapp/article/delete',{params});
// }
//大数据看板 bigApi

export const bigDataApi = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/home_page/bigDataApi',params);
}

export const bigDataPointApi = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/home_page/bigDataPointApi',params);
}



export const creatorList = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/canal_research/creatorList',params);
}  
export const creatorSave = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/canal_research/creatorSave',params);
} 

export const creatorEdit = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.put('api/wxapp/canal_research/creatorSave',params);
}
export const creatorDelete = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.delete('api/wxapp/canal_research/creatorSave',{params});
}
export const creatorEdit2 = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.put('api/wxapp/canal_research/creatorEdit',params);
}
/**
 * 申请同步校园
 * @param {*} params 
 * @returns 
 */
export const creatorShare = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/canal_research/creatorShare',params);
}


/**
 * 申请同步校园
 * @param {*} params 
 * @returns 
 */
 export const getCreatorListApi = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/article/getCreatorList',params);
}
/**
 * 申请同步校园
 * @param {*} params 
 * @returns 
 */
 export const saveCreatorListApi = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/article/saveCreatorList',params);
}

/**
 * 活动管理
 * @param {*} params 
 * @returns 
 */
export const teacherItinerary = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/canal_research/teacherItinerary',params);
}

/**
 * 活动管理详情 使用的图标
 * @param {*} params 
 * @returns 
 */
 export const getImgInit = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/wonderful/getImgInit',params);
}
/**
 * 活动管理详情 状态
 * @param {*} params 
 * @returns 
 */
 export const getStatus = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/canal_research/getStatus',params);
}
export const myItinerary = (data) => {
  var token = localStorage.getItem('token') ?? '';
  return httpInstance.get('/api/wxapp/canal_research/myItinerary', {params:{token:token,...data}})
}
export const myCourse = (data) => {
  var token = localStorage.getItem('token') ?? '';
  return httpInstance.get('/api/wxapp/canal_research/myCourse', {params:{token:token,...data}})
}
/**
 * 研学管理事项确认
 * @param {*} params 
 * @returns 
 */
 export const studyInit = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/canal_research/studyInit',params);
}
/**
 * 研学管理事项保存
 * @param {*} params 
 * @returns 
 */
 export const saveStudyTeacher = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/canal_research/saveStudyTeacher',params);
}

/**
 * 研学管理事项
 * @param {*} params 
 * @returns 
 */
 export const saveRecord = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/canal_research/saveRecord',params);
}


/**
 * 研学管理事项列表
 * @param {*} params 
 * @returns 
 */
 export const recordList = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/canal_research/recordList',params);
}
/**
 * 研学管理事项列表删除
 * @param {*} params 
 * @returns 
 */
 export const delRecord = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/canal_research/delRecord',params);
}
/**
 * 研学管理事项列表删除
 * @param {*} params 
 * @returns 
 */
 export const initObject = (params = {})=>{
  var token = localStorage.getItem('token')??'';
  params.token = token;
  return httpInstance.post('api/wxapp/canal_research/initObject',params);
}

export const getcontract = (params = {}) =>{
  return httpInstance.get('api/wxapp/home_page/getcontract',params);
}